/**
 * @author: Patrick Jominet
 */
export default function () {
    return {
        restrict: 'A',
        scope: {},
        template: require('./con10t-map-menu-legend.html'),
        link: function () {
        }
    }
};
