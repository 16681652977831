export default {
    "facet_topographieart": "Category of topography",
    "facet_antikeroemprovinz": "Ancient roman province",
    "facet_bauordnung": "Architectural order",
    "facet_fundort_map": "Find Spot",
    "facet_schmuckspezifizierung": "Jewelry specification",
    "facet_dekorationsart": "Type of decoration",
    "facet_topographietypus": "Type of topography",
    "facet_einbindung": "Integration",
    "facet_produktform": "Shape of product",
    "facet_produktfunktion": "Function of product",
    "facet_produktmaterialspezifizierung": "Specific category of product",
    "facet_produktmaterial": "Category of product",
    "facet_geo_map": "All types of locations",
    "facet_bildschema": "Type of image",
    "facet_befundmainabstractmorphology": "Morphology",
    "facet_subkategorie_bilder": "Type of inventory",
    "facet_archaeometrischemessungen": "Archaeometric measurements",
    "facet_sammlungskategorie": "Category of collection",
    "facet_bestandsname": "Name of inventory",
    "facet_haltung": "Pose",
    "facet_objektgattung": "Object category",
    "facet_sitetype": "Type of topography",
    "facet_geo_plural": "All types of locations",
    "facet_gattungallgemein": "Category",
    "facet_antikegriechlandschaft": "Ancient greek region",
    "facet_kulturkreis": "Culture area",
    "facet_funktionaleverwendung": "Functional use",
    "facet_erhaltung": "State of preservation",
    "facet_bearbeitungen": "Reworking",
    "facet_thematikmenschen": "Subject humans",
    "facet_archaeologzeugnisse": "Archaeological evidence",
    "facet_thematik": "Subject",
    "facet_aufstellungskontext": "Context of setting",
    "facet_aufbau": "Composition",
    "entity_create_entry": "Create catalogue entry",
    "entity_catalogs": "Catalogs",
    "entity_places": "Places",
    "entity_linked_objects": "Linked objects",
    "entity_no_further_info": "No further information available",
    "entity_images": "Images",
    "entity_analysis": "Analysis",
    "entity_image": "Image",
    "entity_3dmodel": "3D model",
    "entity_other_views": "Other views",
    "ui_category": "Category",
    "ui_serial_number": "Old serial number",
    "facet_gebaeudetypspeziell": "Specific building type",
    "entityId": "Arachne ID",
    "register_register": "Register",
    "register_success": "Registration successful. You will recieve an email soon.",
    "register_please_fill_out": "Please fill out the following form to get an Arachne Account.",
    "ui_reset": "Reset",
    "ui_phone": "Phone",
    "ui_street": "Street",
    "ui_country": "Country",
    "ui_address": "Address",
    "ui_repeat": "Repeat email",
    "ui_surname": "Surname",
    "ui_first_name": "First Name",
    "facet_funktion": "Function",
    "facet_bekleidung": "Clothing",
    "facet_dekoration": "Decoration",
    "facet_subkategoriebestand_level1": "Subcategory (1)",
    "facet_subkategoriebestand_level2": "Subcategory (2)",
    "facet_subkategoriebestand_level3": "Subcategory (3)",
    "facet_subkategoriebestand_level4": "Subcategory (4)",
    "facet_subkategoriebestand_level5": "Subcategory (5)",
    "facet_subkategoriebestand_level6": "Subcategory (6)",
    "facet_subkategoriebestand_level7": "Subcategory (7)",
    "facet_subkategoriebestand_level8": "Subcategory (8)",
    "facet_subkategoriebestand_level9": "Subcategory (9)",
    "facet_subkategoriebestand_level10": "Subcategory (10)",
    "facet_subkategoriebestand_level11": "Subcategory (11)",
    "facet_subkategoriebestand_level12": "Subcategory (12)",
    "facet_subkategoriebestand_level13": "Subcategory (13)",
    "facet_subkategoriebestand_level14": "Subcategory (14)",
    "facet_subkategoriebestand_level15": "Subcategory (15)",
    "facet_regioromitalien": "Regio Rome Italy",
    "facet_fundort_plural": "Find Spots",
    "facet_kategorie_modell3d": "3D Models",
    "facet_nitonanalysisnumber": "Niton Analysis Number",
    "facet_subkategorie_objekt": "Subcategorie",
    "facet_hasImage": "Has Images",
    "facet_ergaenzungen": "Extensions",
    "facet_shape": "Shape",
    "facet_eventtype": "Event Type",
    "facet_materialbeschreibung": "Description of material",
    "facet_applizierteelemente": "Applied Elements",
    "facet_buchtitel": "Book Title",
    "facet_jahr": "Year",
    "facet_autor": "Author",
    "facet_kontext": "Context",
    "ui_previous_queries": "Previous Searches",
    "catalog_delete_type_catalog_name": "Please type in the name of the catalog to confirm.",
    "catalog_show_help": "Show help",
    "catalog_help": "Help",
    "ui_password_changed": "Password successfully changed!",
    "ui_repeat_password": "Repeat password",
    "facet_material": "Material",
    "facet_technik": "Technique",
    "facet_grundform": "Basic Shape",
    "ui_pages": "Pages",
    "facet_personenheroen": "Heroes",
    "facet_personen": "Persons",
    "facet_personenmenschen": "Humans",
    "facet_personentiere": "Animals",
    "facet_herkunftsland": "Country of Origin",
    "facet_personenmythwesen": "Mythical Beeing",
    "facet_klassifizierung": "Classification",
    "facet_personengoetter": "Gods",
    "type_sammler": "Collectors",
    "facet_gottgoettin": "Deity",
    "facet_mischwesen": "Composite Beeing",
    "entity_active_filters": "Active Filters",
    "entity_next_result": "Next Result",
    "entity_previous_result": "Previous Result",
    "entity_shown_result": "Shown Result",
    "ui_to_search_result": "Search Results",
    "ui_sort_order": "Sort Order",
    "facet_image": "Contains Images",
    "facet_literatur": "Literature",
    "facet_aufbewahrungsort_map": "Repository",
    "facet_aufbewahrungsort": "Repository",
    "facet_aufbewahrungsort_plural": "Repositories",
    "facet_fundort": "Find Spot",
    "facet_geo": "Georeferenced Spots",
    "facet_ortsangabe": "Location Type",
    "navbar_contact": "Contact",
    "title": "Title",
    "facet_sprache": "Language",
    "startsite_projects": "Projects",
    "startsite_categories": "Categories",
    "ui_entry": "Entry",
    "startsite_total": "Total",
    "ui_current_query": "Current Query",
    "catalog_help_page_text_0": "Start creating a new catalog by adding a catalog entry.",
    "startsite_show_all_projects": "Show all projects",
    "startsite_show_all_categories": "Show all categories",
    "ui_map": "Map",
    "footer_imprint": "Imprint",
    "navbar_manage_catalogs": "Manage Catalogs",
    "navbar_about_arachne": "About Arachne",
    "navbar_license_order": "License/Order",
    "ui_hideall": "Hide all",
    "ui_projectsearchplaceholder": "Search project...",
    "facet_einschlussform": "Shape of Inclusions",
    "facet_einschlusstyp": "Type of Inclusion",
    "facet_grabungsort": "Excavation Area",
    "facet_surfacetreatmentbezeichner": "Surfacetreatment Identifier",
    "facet_inventorynumber": "Inventorynumber",
    "facet_sherdtype": "Sherdtype",
    "facet_areal": "Areal",
    "facet_stratum": "Stratum",
    "facet_grabungsinternetypnummer": "Internal Typenumber",
    "facet_morphology_5thLevel": "Morphology 5th Level",
    "facet_morphology_3rdLevel": "Morphology 3rd Level",
    "facet_morphology_2ndLevel": "Morphology 2nd Level",
    "facet_morphology_functionalgroup": "Morphology Functional Group",
    "facet_fabricname": "Name of fabric",
    "facet_fabriccommonname": "Common name fabric",
    "facet_befund": "Context",
    "facet_fabricorigin": "Origin",
    "facet_artdergruppe": "Group Type",
    "facet_gebaeudetyp": "Building type",
    "facet_morphology_4thLevel": "Morphology 4th Level",
    "facet_datierungepoche": "Dating, Epoch",
    "facet_ort": "Place",
    "facet_kategorie": "Category",
    "type_subtitle_person": "TODO",
    "ui_current_search": "Current Search",
    "ui_gridmap_heading": "Georeferenced Objects in Arachne",
    "ui_map_menu_overlays_heading": "Overlays",
    "ui_map_menu_baselayer_heading": "Maps",
    "ui_restricted_entities": "Only objects with",
    "ui_object": "Object",
    "ui_object_plural": "Objects",
    "ui_at": "at",
    "ui_place": "Place",
    "ui_place_plural_dative": "places",
    "ui_showobjects": "Show objects",
    "ui_total_small": "total",
    "ui_no_places_found": "No places found",
    "entity_linked_object": "Linked object",
    "ui_showallobjects": "Show all objects",
    "catalogIds": "Catalog",
    "ui_copy_to_clipboard_message": "Copy to clipboard with Strg+C/⌘+C",
    "ui_map_link_heading": "Link to the current map view",
    "ui_of": "of",
    "ui_objects_shown": "Objects in viewport",
    "search_catalog_create": "Create catalog from search result",
    "search_catalog_too-big": "This search result is too big to create a catalog. Use filters to narrow down the result. The maximum is ",
    "ui.register.bot": "Sorry, registration is not possible for robots!",
    "ui.register.fieldMissing.lastname": "Please fill in your surname to proceed with the registration.",
    "ui.register.fieldMissing.email": "Please enter your email address to proceed with the registration.",
    "ui.register.fieldMissing.place": "Please enter a place to proceed with the registration",
    "ui.register.fieldMissing.firstname": "Please fill in your first name to proceed with the registration.",
    "ui.register.fieldMissing.street": "Please enter a street to proceed with the registration.",
    "i.register.fieldMissing.country": "Please select a country to proceed with the registration.",
    "ui.register.usernameTaken": "The username you have chosen is already in use. Please choose another one.",
    "publish_catalog": "Publish catalog",
    "catalog_title": "Catalog title",
    "catalog_author": "Author",
    "catalog_description": "Catalog description",
    "catalog_text": "Catalog text",
    "catalog_visibility": "Visibility",
    "catalog_visibility_private": "Private",
    "catalog_visibility_public": "Public",
    "ui.update.bot": "Sorry, robots are not allowed to modify user data. Please confirm that you are not a machine.",
    "ui.update.emailNotSame": "The email addresses are different.",
    "ui.update.success": "The update of your personal data has been successful.",
    "ui.update.manage": "Edit / manage personal information",
    "ui.update.perform": "Update",
    "everything": "Everything",
    "maintenance_arachne3": "Arachne is currently undergoing maintenance. You can still use the new Arachne 4 beta for accessing the data. Data input in Arachne 3 will be available again shortly.",
    "ui.register.emailTaken": "The email address you chose is already associated to an existing account? Is this your account? If not, please choose another email to proceed.",
    "ui.register.fieldMissing.password": "Please enter a password to proceed with the registration.",
    "ui.register.fieldMissing.username": "Please enter a username to proceed with the registration.",
    "ui.register.fieldMissing.zip": "Please enter a zip code to proceed with the registration.",
    "ui.register.fieldMissing.country": "Please choose a country from the list to proceed with the registration",
    "ui.register.emailsDontMatch": "The emails you entered are different. Please review them.",
    "ui.register.passwordsDontMatch": "The password you entered do not match.",
    "ui_zip": "ZIP Code",
    "entity_catalog_info": "Catalogues including this entry",
    "entity_404": "The request could not be executed.",
    "entity_403": "You don't have permission to access this dataset.",
    "type_bauwerk": "Buildings",
    "type_buchseite": "Book Pages",
    "type_subtitle_bauwerk": "Buildings or monuments, which can also be superordinate contexts for an individual object or multipart monument. Buildings may be part of a topographic unit and may also contain reproductions and receptions.",
    "type_singular_bauwerk": "Building",
    "type_buch": "Books",
    "type_bauwerksteil": "Building components",
    "type_inschrift": "Inscriptions",
    "type_singular_bauwerksteil": "Building component",
    "type_gruppen": "Multipart monuments",
    "type_subtitle_bauwerksteil": "Compilation of the subsections of a building: floors, sections, rooms.",
    "type_realien": "Individual motifs",
    "type_objekt": "Individual objects",
    "type_singular_objekt": "Individual object",
    "type_subtitle_objekt": "Objects of the real world, which are neither multipart monuments, buildings or topographic units. But objects can belong to multipart monuments, buildings or topographic units, and may also contain reproductions or receptions.",
    "type_subtitle_gruppen": "All types of configurations that are not buildings: e.g. ancient statuary groups, postclassical pastiche, pediment compositions, sculptures separated in modern times, but also groups of finds or hoards; sarcophagi consisting of two Individual objects (case and lid); funeral and tomb inventory and finally collection of small finds which were not recorded as a single Individual object.",
    "type_relief": "Scenes",
    "type_singular_relief": "Scene",
    "type_subtitle_realien": "Figures or objects usually found within the context of scenes.",
    "type_reproduktion": "Reproductions",
    "type_marbilder": "Images",
    "type_subtitle_reproduktion": "Three-dimensional objects of the real world reproducing mainly ancient sculptures or buildings, but also engravings. Reproductions can also belong to multipart monuments, buildings, topographic units or collections or serve as an expression of receptions.",
    "type_singular_marbilder": "Image",
    "type_rezeption": "Receptions",
    "type_subtitle_marbilder": "Here you have the possibility to search systematically for images and image-specific information (e.g. photographs…). This search covers all image stock in Arachne.",
    "type_typus": "Types",
    "type_subtitle_rezeption": "Specific perceptions of ancient objects in certain modern reception sources.",
    "type_topographie": "Topographies",
    "type_singular_typus": "Type",
    "type_subtitle_typus": "Contextualization of sculptures (= Individual objects) according to types and schemes (Greek ideal sculpture, Greek and Roman portraits).",
    "type_subtitle_topographie": "Superordinate contexts which incorporate the subordinate topographic units, buildings or objects belonging to the real world. Topographic units are kept separate from buildings if more than one structure is available - structures are however not constitutive for topographic units.",
    "type_singular_reproduktion": "Reproduction",
    "type_sammlung": "Collections",
    "type_singular_topographie": "Topography",
    "type_subtitle_sammlungen": "Private and museum collections residing in buildings and possibly containing objects or multipart monuments, or their reproductions and receptions.",
    "type_singular_rezeption": "Reception",
    "type_singular_sammlungen": "Collection",
    "type_singular_buch": "Book",
    "type_singular_realien": "Individual motif",
    "type_singular_inschrift": "Inscription",
    "type_singular_buchseite": "Book Page",
    "type_singular_gruppen": "Multipart monument",
    "type_person": "Persons",
    "type_singular_person": "Person",
    "type_ort": "Cities",
    "type_singular_ort": "City",
    "ui.register.fieldsMissing": "Please fill out the form to proceed.",
    "ui.update.emailTaken": "The email you have entered is already taken. Please choose another one.",
    "catalog_new_catalog": "Create new catalog",
    "ui_cancel": "Cancel",
    "ui_delete": "Delete",
    "ui_sure_delete": "Are you sure you want to delete?",
    "ui_yes": "Yes",
    "ui_no": "No",
    "ui_dismiss": "Dismiss",
    "catalog_create_entry": "Create new entry",
    "catalog_edit_entry": "Edit entry",
    "catalog_entry_title": "Entry title",
    "catalog_please_login": "Please login for catalogs",
    "catalog_control": "Catalog management",
    "catalog_edit": "Edit catalog",
    "catalog_your_catalog": "Your catalogs",
    "catalog_no_catalog": "No catalogs available",
    "catalog_add": "Create new catalog",
    "catalog_expand_all": "Expand all",
    "catalog_collapse_all": "Collapse all",
    "catalog_choose_catalog": "Choose catalog",
    "ui_login_successfull": "Login successfull. User",
    "ui_forgot_password": "Forgot password",
    "ui.passwordreset.reset": "Reset password",
    "ui.passwordreset.username": "User name",
    "ui.passwordreset.email": "Email address",
    "ui.passwordreset.first-name": "First name",
    "ui.passwordreset.surname": "Surname",
    "ui.passwordreset.zip": "ZIP Code",
    "ui.passwordreset.iamhuman": "I am no robot!",
    "ui.passwordreset.send": "Send",
    "ui.passwordreset.bot": "Please confirm, that you are not a robot.",
    "ui.passwordreset.fieldMissing.username": "Please fill in your username.",
    "ui.passwordreset.fieldMissing.email": "Please fill in you email address.",
    "ui.passwordreset.fieldMissing.firstname": "Please fill in your first name.",
    "ui.passwordreset.fieldMissing.zip": "Please fill in your ZIP",
    "ui.passwordreset.fieldMissing.all": "Please fill in your data.",
    "ui.passwordreset.success": "Your password has been reset successfully. You should receive an email from us soon.",
    "entity.image.back_to": "Back to",
    "entity.image.download": "Download",
    "entity.image.metadata": "Image metadata",
    "entity.image.fullscreen": "Fullscreen",
    "entity.image.all_images": "All images",
    "ui.passwordreset.unkownuser": "The data you filled in is either incorrect or no existing user for these data could be found. Please try again or mail to idai.objects@dainst.de.",
    "ui.passwordactivation.success": "Your password has been set successfully.",
    "ui.passwordactivation.newpassword": "New password",
    "category_all": "All categories",
    "category_browse_specific": "grouped by filters (Filter-Index)",
    "catalog_403": "You don't have permission to access this catalog.",
    "category_all_data_sets": "All data sets in Arachne",
    "ui_goto": "goto",
    "entity_resolution_login": "You need to be logged in to view this image in high resolution.",
    "ui_show": "show",
    "navbar_edit_profile": "Edit profile",
    "projects_all_projects": "All projects in Arachne",
    "search_no_results": "No results found for",
    "search_no_results_rights": "You may not have the necessary access rights. Please contact the address given on the project entry page.",
    "search_current_filters": "with current filters",
    "ui_no_title": "has no title",
    "3Dinfo_flight_control": "Flight control:",
    "3Dinfo_modeller": "Modeller",
    "ui_license": "License",
    "3Dinfo_file_format": "File format",
    "ui_mouse_pointer": "Mouse pointer",
    "3Dinfo_look": "look",
    "3Dinfo_left_mouse_button": "Left mouse button",
    "3Dinfo_right_mouse_button": "Right mouse button",
    "ui_up": "Up",
    "ui_down": "Down",
    "3Dinfo_move_forward": "Move forward",
    "3Dinfo_move_backward": "Move backward",
    "ui_left": "Left",
    "ui_right": "Right",
    "3Dinfo_move_left": "Move left",
    "3Dinfo_move_right": "Move right",
    "3Dinfo_middle_mouse_button": "Middle mouse button / mouse wheel",
    "3Dinfo_trackball_control": "Trackball control:",
    "3Dinfo_move_target": "Move camera target",
    "3Dinfo_move_up": "Move up",
    "3Dinfo_move_down": "Move down",
    "3Dinfo_freeze": "Freeze/unfreeze camera",
    "ui_create_link": "Create link",
    "ui_target": "Target",
    "ui_linkdescription": "Link description",
    "type_subtitle_relief": "Thematically or formal self-contained section of a character sequence, which is in the context of a carrier medium, for example, a battle group within a frieze cycle.",
    "type_subtitle_inschrift": "A on a carrier, such as stone, wood or metal, fitted writing.",
    "type_subtitle_buch": "Digitization, characterization and contextualization scientifically prints about the antiquity from the 16th to 19th century",
    "type_subtitle_buchseite": "Illustration of individual book pages in high resolution",
    "navbar_change_password": "Change password",
    "catalog_delete_entry": "Delete entry",
    "catalog_delete": "Delete catalog",
    "ui_show_more": "Show more",
    "ui_more_entries": "more entries",
    "catalog_show_entity": "Show connected object",
    "catalog_show_text": "Show catalog text",
    "catalog_create_entry_child": "Create entry below this entry",
    "ui_one_more_entry": "one more entry",
    "catalog_entry_entity": "Connected object",
    "ui_optional": "Optional",
    "ui_not_logged_in": "You are not allowed to access this page. Please log in to proceed.",
    "facet_projekttitel": "Project",
    "ui_search_too_big": "The search result size exceeds 10000 entries. Please narrow down your search.",
    "entity.image.rotate": "Rotate",
    "catalog_no_description": "No catalog text given",
    "ui_objects_total": "Objects total",
    "catalog_public_by": "Public catalog by",
    "catalog_view_full_entry": "View full catalog entry",
    "ui_close": "Close",
    "catalog_browse": "Browse catalog",
    "catalog_generate_texts": "Generate catalog entry texts",
    "subtitle": "Subtitle",
    "catalog_map": "Show catalog entries on map",
    "catalog_list": "Show catalog outline",
    "type_literatur": "Literature",
    "facet_land": "Country",
    "ui_countryname_eg": "Egypt",
    "ui_countryname_al": "Albania",
    "ui_countryname_dz": "Algeria",
    "ui_countryname_aq": "Antarctica",
    "ui_countryname_ag": "Antigua and Barbuda",
    "ui.no_title": "Without title",
    "facet_kommentar": "Comments",
    "catalog_help_page_title_0": "Creating catalog entries",
    "catalog_help_page_text_1": "The menu \"Create new entry\" allows you to specify the title of the entry. You may also connect an object with the new entry.",
    "catalog_help_page_text_2": "Use the editor to type in and format the catalog text. You may make use of the possibilities of the markup language Markdown.",
    "catalog_help_page_text_3": "You can add catalog entries below an existing entry to create a new level of the hierarchy.",
    "catalog_help_page_text_4": "Move entries up or down by drag & drop to change their order.",
    "catalog_help_page_text_5": "Drag entries to the left or to the right in order to move them to another level of the hierarchy.",
    "catalog_help_page_text_6": "Please notice that only the last entry on a level can be moved to higher level of the hierarchy.",
    "catalog_help_page_text_7": "Before adding an entry to a list of child entries, you have to open the corresponding list. Do so by clicking the folder icon to the left of the entry.",
    "catalog_help_page_text_8": "Newly created catalogs are only visible to yourself. You may publish your catalog by adjusting the visibility setting in the \"edit catalog\" menu.",
    "catalog_help_page_title_1": "Specifying title and connected object",
    "catalog_help_page_title_2": "Typing in the catalog text",
    "catalog_help_page_title_3": "Creating hierarchies",
    "catalog_help_page_title_4": "Adjusting the entry order",
    "catalog_help_page_title_5": "Changing the hierarchy level of entries",
    "catalog_help_page_title_6": "Moving entries to a higher level",
    "catalog_help_page_title_7": "Moving entries to a lower level",
    "catalog_help_page_title_8": "Publishing catalogs",
    "catalog_help_markdown_link_url": "https://en.wikipedia.org/wiki/Markdown",
    "catalog_help_markdown_link_description": "Click here for further information about Markdown.",
    "catalog_help_previous": "Previous",
    "catalog_help_next": "Next",
    "facets": "Facets",
    "facet_values": "Filter Values",
    "facet_thema": "Subject",
    "facet_lebensalter": "Age",
    "facet_fotonrextern": "Photo number extern",
    "facet_geschlecht": "Gender",
    "ui_show_less": "Show less",
    "type_sammlungen": "Collections",
    "ui.passwordreset.fieldMissing.lastname": "Please fill in your last name.",
    "facet_aufnahmedatum": "Date taken",
    "facet_schlagworte": "Subjects",
    "msg_confirm_dismiss_changes": "Dismiss changes?",
    "ui.passwordchange.change": "Change password",
    "ui_pwdchange": "Change password?",
    "ui.passwordchange.oldpassword": "Old password",
    "ui.passwordchange.newpassword": "New password",
    "ui.passwordchange.repeat-newpassword": "Repeat new password",
    "ui.passwordchange.success": "Change successful",
    "ui.passwordchange.wrongpassword": "You entered your password incorrectly!",
    "ui.passwordchange.wrongCheck": "The new passwords don't match!",
    "facet_wesen": "Nature",
    "facet_bewaffnung": "Armament",
    "facet_attributallg": "General attributs",
    "facet_realienart": "Type of realia",
    "facet_bennenungallg": "General designation",
    "ui.passwordchange.illegal": "The old and new password can't be the same!",
    "edit_button": "Edit",
    "undefined": "undefined",
    "catalog_no_catalog_info": "A catalog in Arachne is an ordered list of archaeological objects.",
    "general_hint": "Note:",
    "catalog_no_catalog_hint_note": "Catalogs are private by default, so they are not publicly visible. In addition, further editors must be activated separately.",
    "catalog_no_catalog_help": "By clicking on \"Create new catalog\", you can create your own personal catalog. You can find more help on editing entries by opening the catalog.",
    "ui_on": "On",
    "ui_relevance": "Relevance",
    "ui_tiles": "Tiles",
    "ui_list": "List",
    "ui_user_not_found": "No user with this name could be found.",
    "catalog_add_user": "Add editor",
    "catalog_no_editor": "This user does not have editorial powers or got already added to this catalog.",
    "catalog_editors": "Editors",
    "catalog_atleast_one_editor": "A catalog needs to have at least one editor.",
    "facet_datierungargument": "Dating, Argument",
    "facet_fundkontext": "Find context",
    "catalog_projectId": "Project ID",
    "catalog_delete_entries": "Delete multiple entries",
    "catalog_delete_entries_button": "Delete entries",
    "entity_project_info": "Projects including this entity",
    "entity_projects": "Projects",
    "type_singular_literatur": "Literature",
    "facet_roemgriech": "Typefield",
    "type_singular_sammler": "Collector",
    "type_gruppierung": "Groupings",
    "type_singular_gruppierung": "Grouping",
    "type_singular_modell3d": "3D model",
    "type_subtitle_literatur": "TODO",
    "type_subtitle_ort": "TODO",
    "type_subtitle_sammler": "TODO",
    "type_subtitle_gruppierung": "TODO",
    "type_subtitle_modell3d": "TODO",
    "facet_gattungspeziell": "Type Addition",
    "search_400": "Invalid search request. Please check if you have escaped all special characters correctly. For more information on how to do this, please check the FAQ: https://arachne.dainst.org/info/faq",
    "ui_map_legende": "Number of hits",
    "ui_map_legende_min": "low",
    "ui_map_legende_max": "high",
    "search_500": "We encountered an internal server error while processing your search request. This should not happen. Please contact idai.objects@dainst.de so that we may fix this issue.",
    "catalog_preview": "Overview",
    "ui_download_as": "Download as",
    "ui_download_limit": "Set amount of entities",
    "ui_download_limit_other": "Custom value",
    "ui_download_select": "Please select a desired file type",
    "ui_download": "Download",
    "ui_download_big": "Be aware, the selection is very big and it could take a while to generate the file.",
    "ui_download_too-big": "The selection exceeds the limit of 10000 entities. Please use filters to narrow the selection.",
    "search_whole_arachne": "Serach in the entire Arachne.",
    "search_current_scope": "in the search scope",
    "group_facet_place": "Place",
    "group_facet_material": "Material",
    "group_facet_funktion": "Function",
    "group_facet_thematik": "Subject",
    "facet_nominal": "Nominal Value",
    "facet_muenzstaette": "Mint",
    "group_facet_zustand": "Condition",
    "group_facet_motiv": "Motive",
    "group_facet_typ": "Type",
    "add_entity_to_catalogue": "Add entity to catalogue",
    "group_facet_buildingtype": "Buildingtype",
    "facet_modal_search": "Search for a filter...",
    "facet_modal_result": "Found",
    "ui_unsaved-changes": "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?",
    "catalog_create_entries": "Create multiple entries",
    "catalog_singular_entry": "Single entry",
    "catalog_multiple_entries": "Multiple entries",
    "catalog_import_by_id": "Import multiple entries via entity ID",
    "catalog_import_by_id_instructions": "Enter all entitiy IDs to be imported in the text field below",
    "catalog_add_entitiyids_comma_separated": "Separate multiple entity IDs with a comma and do not use spaces",
    "catalog_create_entries_instructions": "After the list is complete, confirm with the OK button",
    "catalog_add_entry_help": "Add an entry to this list.",
    "catalog_remove_entry_help": "Remove this entry from the list",
    "translocations": "translocations",
    "show_translocation_view": "Show Object Movements",
    "translocation_view_to_many_results": "Too many results found for object movement visualization. Please zoom in or filter results in if you want to see movements.",
    "bounding_box": "Bounding Box",
    "ui_data_protection_pre": "I have read and accept the",
    "ui_data_protection_url": "https://www.dainst.org/datenschutz",
    "ui_data_protection_post": ".",
    "ui_data_protection": "data protection policy",
    "ui.register.dataProtection": "Please confirm the data protection policy to proceed with the registration.",
    "facet_region": "Region",
    "facet_subregion": "Subregion",
    "facet_locality": "Locality",
    "facet_city": "City",
    "catalog_project": "View project page",
    "ui_forgot_password_alert_pre": "Forgot your password? Click",
    "ui_forgot_password_alert": "here",
    "ui_forgot_password_alert_post": "to reset it.",
    "catalog_download": "Download Catalog",
    "ui_ok": "OK",
    "ui_data_export_status_finished": "Finished",
    "ui_data_export_status_error": "Error",
    "ui_data_export_status_enqueued": "Enqueued",
    "ui_data_export_status_running": "Running",
    "ui_data_export_status": "Data-Export - Status",
    "ui_owner": "Owner",
    "ui_created_at": "Created At",
    "ui_started_at": "Started At",
    "ui_media_type": "Filetype",
    "ui_url": "URL",
    "ui_status": "Status",
    "ui_data_export_other_user": "This exports belongs to another user. You see it because you are an admin.",
    "data_export_to_huge_and_will_be_sent_by_mail": "The number of records in this export is very large and the export will thus take a while. You will receive an email when it's ready.",
    "data_export_to_huge_and_not_logged_in": "The mumber of records to export exceeds limit for anonymous users: Please log in and try again.",
    "data_export_io_error": "IO-Error",
    "data_export_no_admin": "You have to be an admin to do this.",
    "data_export_not_found": "File not Found",
    "data_export_stack_full": "Data export queue is full. Please try again later.",
    "ui_data_export_none": "There is no data export in your list.",
    "ui_restore_call": "Restore Source",
    "ui_catalog": "Catalog",
    "ui_searchResult": "Search Result",
    "ui_to": "to",
    "data_export_to_huge": "This export contains too many records.",
    "type_mainabstract": "Ceramics",
    "ui.update.error": "An unexpected error occured while updating your data.",
    "type_modell3d": "3D models",
    "catalog_duplicate_entry_child": "Duplicate Entry",
    "all_images": "Show all",
    "show_images": "images",
    "ui_running_for": "Running for",
    "all": "All",
    "ui_please_select": "Please select",
    "ui_username": "User Name",
    "ui_password": "Password",
    "ui_entries": "Entries",
    "ui_total": "Total",
    "footer_privacy": "Privacy",
    "backend_missing": "Connection to the arachne backend cannot not be established.",
    "default": "An unknown error has occured.",
    "ui_filter_plural": "Filters",
    "pick_an_item": "Pick an item",
    "form_save": "Save",
    "form_reset": "Reset",
    "picker_perform_search": "Perform a search to pick an item",
    "picker_no_result": "No items found",
    "results": "Results",
    "everything_fine": "Everything fine",
    "components.message.contact": "Please contact CONTACT if the errors persist.",
    "ui_login": "login",
    "ui_login_failed": "Login failed. Please try again!",
    "ui_page": "Page",
    "ui_previous": "Previous",
    "ui_next": "Next",
    "contact_success": "Message has been sent. We will contact you by email as soon as possible.",
    "navbar_sign_up": "Sign Up",
    "footer_licensed_under": "Licensed under",
    "footer_bugs_to": "Report bugs to",
    "contact_first_surname": "First name & surname",
    "ui_subject": "Subject",
    "ui_message": "Message",
    "ui_nomachine": "I'm not a machine",
    "navbar_placeholder_search": "Search for",
    "ui_send": "Send",
    "ui_placeholder_search": "Search",
    "ui_showall": "Show all",
    "navbar_sign_in": "Sign In",
    "test": "from \"shared\"",
    "navbar_sign_out": "Sign Out",
    "navbar_old_searches": "Recently searched for",
    "ui_back": "Back",
    "ui_add_value": "Add value",
    "ui_add_tag": "Add tag",
    "ui_add_name": "Add name",
    "ui_add_language": "Add language",
    "navbar_this_scope": "This Scope",
    "navbar_leave_scope": "Leave Search Scope",
    "facet_titel": "Title",
    "facet_ethnienationalitaet": "Nationality",
    "ui.con10t-network.too_many_entities": "Please narrow down your search to 300 results or less.",
    "group_facet_person": "People",
    "facet_personroleauthor": "Authors",
    "facet_personroletopic": "Mentioned",
    "facet_personroleaddressee": "Addressees",
    "facet_personrolenone": "Other",
    "catalog_progress": "Catalog is being created",
    "catalog_progress_complete": "Completed successfully",
    "catalog_progress_catalog_link": "Show catalog"
}
